import React from "react"
import ArticleList from "../components/shared/article-list"
import SectionTitle from "../components/shared/section-title"

export default function Search() {
  return(
    <>
      <SectionTitle>Articles</SectionTitle>
        <ArticleList />
    </>

  )
}
